import { useMemo } from "react";
import {
  LOCAL_STORAGE_ACTIVE_QUESTS,
  LOCAL_STORAGE_AUTH_ATTEMPTS,
  LOCAL_STORAGE_AUTH_REAUTH,
  LOCAL_STORAGE_AUTH_REDIRECT,
  LOCAL_STORAGE_AUTH_REF,
  LOCAL_STORAGE_AUTH_USER,
  LOCAL_STORAGE_AUTH_UTM,
} from "../consts/storageKeys";
import { reclaim } from "../reclaim-api";
import { getLocalStorage, getSessionStorage, removeLocalStorage, removeSessionStorage } from "../utils/local-storage";
import { browser } from "../utils/platform";
import { useCallbackSafeRef } from "./useCallbackSafeRef";
import { useOurRouter } from "./useOurRouter";

export const useGoogleLogin = () => {
  const router = useOurRouter();

  const urlParams = useMemo(() => new URLSearchParams(browser().isBrowser ? window.location.search : ""), []);

  return useCallbackSafeRef(() => {
    removeLocalStorage(LOCAL_STORAGE_AUTH_REAUTH);
    removeLocalStorage(LOCAL_STORAGE_AUTH_ATTEMPTS);
    removeSessionStorage(LOCAL_STORAGE_ACTIVE_QUESTS);

    const storedUser = getLocalStorage(LOCAL_STORAGE_AUTH_USER);
    const ref = getLocalStorage(LOCAL_STORAGE_AUTH_REF);
    const utm = getLocalStorage(LOCAL_STORAGE_AUTH_UTM);

    const slackTeamId = urlParams.get("slackTeamId");
    const slackUserId = urlParams.get("slackUserId");

    const invite = urlParams.get("invite");

    // the check for "://" prevents arbitrary redirection (Open Redirect)
    const oauthRedirect = router.query.state?.redirect;
    const redirect = `${
      !!oauthRedirect && !oauthRedirect.includes("://")
        ? oauthRedirect
        : getSessionStorage(LOCAL_STORAGE_AUTH_REDIRECT, "/")
    }${!!invite ? "?invite=1" : ""}`;

    removeSessionStorage(LOCAL_STORAGE_AUTH_REDIRECT);

    const state = {
      flow: "default",
      redirect,
      slackTeamId,
      slackUserId,
      ref,
      utm,
    };

    reclaim.users.authRedirect("google", storedUser?.email, state);
  });
};
